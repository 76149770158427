<template>
    <div class="home-wrap client-width overflow-h overflow-y">
        <Header class="paddingl45 paddingt15 paddingr90" />
        <div class="login-content">
            <img class="headimg" src="../../assets/product_headimg.png" alt="" />
            <div class="title">核心产品介绍</div>
            <div class="tips">Product</div>
            <div class="type_box dis-f justify-center algin-center">
                <div class="type_box_item  dis-f justify-center align-center flex-column" @click="scropFun('carousel', 0)">
                    <img src="../../assets/history.png" alt="" />
                    <div class="type c-darkgray text-center">车辆历史报告</div>
                </div>
                <div class="type_box_item  dis-f justify-center align-center flex-column" @click="scropFun('carousel', 1)">
                    <img src="../../assets/dianchi.png" alt="" />
                    <div class="type c-darkgray text-center">动力电池检测报告</div>
                </div>
                <div class="type_box_item  dis-f justify-center align-center flex-column" @click="scropFun('carousel', 2)">
                    <img src="../../assets/weibao.png" alt="" />
                    <div class="type c-darkgray text-center">车辆维保报告</div>
                </div>
                <div class="type_box_item  dis-f justify-center align-center flex-column" @click="scropFun('carousel', 3)">
                    <img src="../../assets/guzhi.png" alt="" />
                    <div class="type c-darkgray text-center">车辆估值报告</div>
                </div>
                <div class="type_box_item  dis-f justify-center align-center flex-column" @click="scropFun('carousel', 4)">
                    <img src="../../assets/zhaohui.png" alt="" />
                    <div class="type c-darkgray text-center">车辆召回报告</div>
                </div>
            </div>
            <div class="advantage">
                <div class="title title2">产品核心优势</div>
                <div class="tips">Product</div>
                <div class="content dis-f justify-center align-center">
                    <div class="left">
                        <div class="dis-f justify-center flex-column">
                            <div class="left_item  dis-f justify-center align-center">
                                <img src="../../assets/icon1.png" alt="" />
                                <div class="tip">合法</div>
                            </div>
                            <div class="des">
                                数据来源合法合规，信息脱敏安全使用，保护消费者和企业合法利益。
                            </div>
                        </div>
                        <div class="dis-f justify-center flex-column margint125">
                            <div class="left_item  dis-f justify-center align-center">
                                <img src="../../assets/icon4.png" alt="" />
                                <div class="tip">全面</div>
                            </div>
                            <div class="des">
                                覆盖车辆全生命周期的车况信息，全方位服务二手车交易，提高流通能效。
                            </div>
                        </div>
                    </div>
                    <img class="center" src="../../assets/logo.png" alt="" />
                    <div class="left">
                        <div class="dis-f justify-center flex-column">
                            <div class="left_item  dis-f justify-center align-center">
                                <img src="../../assets/icon3.png" alt="" />
                                <div class="tip">权威</div>
                            </div>
                            <div class="des">
                                中国汽车流通协会发起及建设，打造有公信力的二手车行业诚信体系。
                            </div>
                        </div>
                        <div class="dis-f justify-center flex-column margint125">
                            <div class="left_item  dis-f justify-center align-center">
                                <img src="../../assets/icon2.png" alt="" />
                                <div class="tip">高效</div>
                            </div>
                            <div class="des">
                                快捷、便利、高质量的二手车信息服务，助力车商降本增效。
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="title" ref="carousel" id="carousel">产品服务流程</div>
            <div class=" dis-f justify-between align-center marginb50">
                <div class="tips">Product</div>
                <a class="tips2">产品服务入口</a>
            </div>
            <el-carousel ref="carouselBox" :initial-index="imgIndex" height="634px" class="overflow-h client-width"
                :interval="5000">
                <el-carousel-item v-for="(item, index) in bannerList" :key="index" :name="item.name">
                    <div class="bg dis-f justify-between align-center" style="width: 91%; margin: 0 auto;">
                        <div class="carousel_left">
                            <div class="name">{{ item.name }}</div>
                            <div class="item">累积查询超过百万</div>
                            <div class="item">API平台客户对接</div>
                            <div class="item">7*24小时在线客服</div>
                            <div class="total">2021-2022识别问题车辆统计</div>
                            <div class="icon_box dis-f justify-between align-center">
                                <div class="dis-f justify-center align-center flex-column">
                                    <img class="icon" src="../../assets/dq.png" alt="" />
                                    <div class="num">2860</div>
                                </div>
                                <div class="dis-f justify-center align-center flex-column">
                                    <img class="icon" src="../../assets/sy.png" alt="" />
                                    <div class="num">27521</div>
                                </div>
                                <div class="dis-f justify-center align-center flex-column">
                                    <img class="icon" src="../../assets/wja.png" alt="" />
                                    <div class="num">134520</div>
                                </div>
                                <div class="dis-f justify-center align-center flex-column">
                                    <img class="icon" src="../../assets/hs.png" alt="" />
                                    <div class="num">889</div>
                                </div>
                                <div class="dis-f justify-center align-center flex-column">
                                    <img class="icon" src="../../assets/qs.png" alt="" />
                                    <div class="num">23706</div>
                                </div>
                            </div>
                        </div>
                        <el-image class="report_img" :src="item.reportImg" :fit="'cover'"
                            :preview-src-list="item.srcList"></el-image>
                        <div class="dis-f justify-center flex-column align-center">
                            <div class="bg-white padding10">
                                <el-image class="erweima" :src="item.gongzhonghao" :fit="'cover'"></el-image>
                                <div class="tips_content text-center">公众号二维码</div>
                            </div>
                            <div class="bg-white padding10 maringt10">
                                <el-image class="erweima" :src="item.xiaochengxu" :fit="'cover'"></el-image>
                                <div class="tips_content text-center">小程序二维码</div>
                            </div>
                        </div>
                    </div>
                </el-carousel-item>
            </el-carousel>
            <!-- <div class="home-footer c-dark fs-18 paddingv25 text-center">
                
                <div class=" dis-f align-center justify-center">
                    <a class="c-dark" href="https://beian.miit.gov.cn">备案号：京ICP备14052450号</a>

                    <div class="marginl10 dis-f align-center justify-center">
                        <img class="beian" src="@/assets/beian.png" alt="">
                        <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502051529"
                            class="c-dark">京公网安备
                            11010502051529号</a>
                    </div>

                </div>
                <div>
                    Copyright@ 2020 北京与车行信息技术有限公司版权所有

                </div> 
            </div>-->
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
    components: {
        Header,
        Footer
    },
    data() {
        return {
            bannerList: [
                {
                    reportImg: require("../../assets/histortReport.png"),
                    name: "车辆历史报告",
                    gongzhonghao: require("../../assets/gongzhonghao.png"),
                    xiaochengxu: require("../../assets/xiaochengxu.png"),
                    srcList: [require("../../assets/histortReport2.png"), require("../../assets/dianchiReport2.png"), require("../../assets/weibaoReport2.png"), require("../../assets/guzhiReport2.png"), require("../../assets/zhaohuiReport2.png")]
                },
                {
                    reportImg: require("../../assets/dianchiReport.png"),
                    name: "动力电池检测报告",
                    gongzhonghao: require("../../assets/gongzhonghao.png"),
                    xiaochengxu: require("../../assets/xiaochengxu.png"),
                    srcList: [require("../../assets/dianchiReport2.png"), require("../../assets/histortReport2.png"), require("../../assets/weibaoReport2.png"), require("../../assets/guzhiReport2.png"), require("../../assets/zhaohuiReport2.png")]
                },
                {
                    reportImg: require("../../assets/weibaoReport.png"),
                    name: "车辆维保报告",
                    gongzhonghao: require("../../assets/gongzhonghao.png"),
                    xiaochengxu: require("../../assets/xiaochengxu.png"),
                    srcList: [require("../../assets/weibaoReport2.png"), require("../../assets/histortReport2.png"), require("../../assets/dianchiReport2.png"), require("../../assets/guzhiReport2.png"), require("../../assets/zhaohuiReport2.png")]
                },
                {
                    reportImg: require("../../assets/guzhiReport.png"),
                    name: "车辆估值报告",
                    gongzhonghao: require("../../assets/gongzhonghao.png"),
                    xiaochengxu: require("../../assets/xiaochengxu.png"),
                    srcList: [require("../../assets/guzhiReport2.png"), require("../../assets/histortReport2.png"), require("../../assets/dianchiReport2.png"), require("../../assets/weibaoReport2.png"), require("../../assets/zhaohuiReport2.png")]
                },
                {
                    reportImg: require("../../assets/zhaohuiReport.png"),
                    name: "车辆召回报告",
                    gongzhonghao: require("../../assets/gongzhonghao.png"),
                    xiaochengxu: require("../../assets/xiaochengxu.png"),
                    srcList: [require("../../assets/zhaohuiReport2.png"), require("../../assets/histortReport2.png"), require("../../assets/dianchiReport2.png"), require("../../assets/weibaoReport2.png"), require("../../assets/guzhiReport2.png")]
                }

            ],
            scrollReportBox: false,
            imgIndex: 0,
        }
    },

    methods: {
        scropFun(val, index = 0) {
            this.imgIndex = index;
            if (this.$refs.carousel) {
                this.$refs.carouselBox.setActiveItem(this.imgIndex)
            }

            this.$nextTick(() => {
                this.$refs[val] && window.scrollTo(0, (this.$refs[val]).offsetTop);

                let y = null;

                const scrollTop =
                    document.documentElement.scrollTop || document.body.scrollTop;
                const reportOffsetTop =
                    (this.$refs.carousel && (this.$refs.carousel).offsetTop) || 0;

                this.scrollReportBox = scrollTop >= reportOffsetTop - 100;

                window.onscroll = () => {
                    if (!y) {
                        y = window.pageYOffset;
                    }
                    const diffY = y - window.pageYOffset;

                    if (diffY < 0) {
                        const scrollTop =
                            document.documentElement.scrollTop || document.body.scrollTop;
                        const reportOffsetTop =
                            (this.$refs.carousel && (this.$refs.carousel).offsetTop) ||
                            0;

                        this.scrollReportBox = scrollTop >= reportOffsetTop - 200;
                    }
                    y = window.pageYOffset;
                };
            });
        }
    }

}
</script>
<style scoped lang="scss">
.home-wrap {
    width: 100%;
    max-width: 1366px;
    margin: 0 auto;
}

.home-wrap::-webkit-scrollbar {
    display: none;
}

.login-content {
    // width: 1200px;
    margin: 0 auto;
    margin-top: 35px;
}

.headimg {
    width: 100%;
    height: 360px;
}

.title {
    /*padding-left: 8px;*/
    padding-left: 70px;
    height: 60px;
    font-size: 44px;
    font-family: "futura-font";
    font-weight: 600;
    color: #333333;
    line-height: 60px;
    position: relative;
    margin-top: 60px;

    &::before {
        content: "";
        width: 8px;
        height: 44px;
        display: inline-block;
        background: #d8d8d8;
        border-radius: 4px;
        border: 1px solid #979797;
        position: absolute;
        top: 5px;
        /*left: 0px;*/
        left: 60px;
    }
}

.title2 {
    margin-top: 0;
}

.tips {
    // padding-left: 81px;
    height: 44px;
    font-size: 32px;
    font-family: "futura-font";
    font-weight: 600;
    color: #999999;
    line-height: 44px;
    padding-left: 70px;
}

.tips2 {
    height: 44px;
    /*font-size: 32px;*/
    font-size: 28px;
    font-family: "futura-font";
    font-weight: 600;
    color: #005ae9;
    line-height: 44px;
    margin-right: 60px;
}

.type_box {
    margin-top: 60px;
    // padding-left: 81px;

    &_item {
        /*margin: 0 8px;*/
        margin: 0 18px;
        width: 220px;
        height: 330px;
        background: #ffffff;
        box-shadow: 0px 0px 24px 0px #f7f7f7;
        border-radius: 16px;
        border: 1px solid #dddddd;

        &:first-child {
            margin-left: 0px;
        }

        &:last-child {
            margin-right: 0;
        }

        img {
            width: 74px;
            height: 81px;
        }

        .type {
            height: 33px;
            font-size: 24px;
            font-family: "futura-font";
            font-weight: 600;
            line-height: 33px;
            margin-top: 37px;
        }
    }
}

.advantage {
    width: 100%;
    height: 680px;
    background: #f9f9f9;
    border-radius: 160px 0px 0px 0px;
    padding-top: 60px;
    margin-top: 60px;

    .content {
        padding: 0 82px;

        .left_item {
            width: 200px;
            height: 60px;
            background: #e3eaf8;
            border-radius: 55px;

            img {
                width: 36px;
                height: 36px;
            }

            .tip {
                font-family: "futura-font";
                font-weight: 600;
                color: #005ae9;
                font-size: 44px;
                margin-left: 12px;
            }
        }

        .des {
            width: 284px;
            height: 44px;
            font-size: 16px;
            font-family: "futura-font";
            font-weight: 600;
            color: #666666;
            line-height: 22px;
            margin-left: 32px;
            margin-top: 28px;
        }

        .center {
            width: 440px;
            height: 440px;
            margin-left: 35px;
            margin-right: 47px;
        }
    }
}

.margint125 {
    margin-top: 125px;
}

.carousel_left {
    width: 390px;
    height: 540px;
    background: #e3eaf8;

    .name {
        width: 100%;
        height: 60px;
        font-size: 44px;
        font-family: "futura-font";
        font-weight: 600;
        color: #005ae9;
        line-height: 60px;
        text-align: center;
        margin-top: 75px;
    }

    .item {
        margin-top: 24px;
        width: 100%;
        height: 27px;
        font-size: 20px;
        font-family: "futura-font";
        font-weight: 600;
        color: #333333;
        line-height: 27px;
        text-align: center;
    }

    .total {
        width: 100%;
        height: 33px;
        font-size: 24px;
        font-family: "futura-font";
        font-weight: 600;
        color: #005ae9;
        line-height: 33px;
        text-align: center;
        margin-top: 12px;
        margin-bottom: 18px;
    }
}

.report_img {
    width: 251px;
    height: 476px;
    margin: 32px 0;
}

.erweima {
    width: 186px;
    height: 186px;
}

.icon {
    width: 60px;
    height: 60px;
}

.num {
    width: 57px;
    height: 22px;
    font-size: 16px;
    font-family: "futura-font";
    font-weight: 600;
    color: #333333;
    line-height: 22px;
    text-align: center;
    margin-top: 8px;
}

.icon_box {
    padding: 0 24px;
}

.bg {
    background: #f9f9f9;
    padding-right: 24px;
}


.maringt10 {
    margin-top: 20px;
}

.tips_content {
    font-family: "futura-font";
    font-size: 12px;
    color: #333333;
}
.paddingr90{
    padding-right: 90px;
}
</style>